<template>

  <div id="Blog">
    <articles>
    </articles>
  </div>
    
</template>

<script>
    export default {
      name: 'Blog',
        components: {
         Articles: () => import('@/components/home/Articles'),
        },       
    }
</script>